import InstrumentCard from "./instrumentCard";

export default function InstrumentCards(props) {
        return (
            <div className="learn">
                {
                    props.instrumentsData.map(instrument =>
                        <InstrumentCard key={instrument.id} instrument={instrument}/>
                    )
                }
            </div>
        )
}
