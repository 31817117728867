import {Link} from "react-router-dom";

export default function NotFound() {


    return (
        <div className="not-found">
            <div>
                <h1>Oops, nothing here!</h1>
                <Link to="/">Back to civilization wtih you!</Link>
            </div>
        </div>
    )
}
