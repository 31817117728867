import {Link} from "react-router-dom";

export default function InstrumentCard(props) {

    const instrument = props.instrument;
    const src = instrument.name.toLowerCase().replace(" ","");
    const InstrumentImage = require(`../img/header-${src}.jpg`)

    return (
        <Link style={{backgroundImage: `url(${InstrumentImage})`}}
              data-instrumentid={instrument.id}
              to={"instruments/"+src}
              className={instrument.name === 'comingsoon' ? 'coming-soon' : ''}>
            {instrument.name}
        </Link>
    )
}
