import Logo from '../img/jms-logo.png';
import {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";

export default function Header(props) {

    const [navOpen, setNavOpen] = useState(false);
    const [lessonsOpen, setLessonsOpen] = useState(false);
    const [instrumentsDropdown, setInstrumentsDropdown] = useState();

    const handleNavToggle = () =>{
        if(navOpen){
            setNavOpen(false);
        } else {
            setNavOpen(true)
        }
    }

    const handleLessonsToggle = (e) => {
        if(lessonsOpen){
            if (!e.currentTarget.contains(e.relatedTarget)) {
                setLessonsOpen(false);
            }
        } else {
            setLessonsOpen(true)
        }
    }


    useEffect(()=>{
        const nav = document.getElementsByTagName("nav")[0];
        if(window.innerWidth<768){
            if(navOpen){
                nav.style.display = "flex";
            } else {
                nav.style.display = "none";
            }
        }
        const lessons_dropdown = document.getElementsByClassName("lessons-dropdown")[0];
        if (lessonsOpen){
            lessons_dropdown.style.display = "flex";
        } else {
            lessons_dropdown.style.display = "none";
        }
        setInstrumentsDropdown(props.instrumentsData.map(instrument =>
            <li onClick={handleNavToggle} key={instrument.id}><NavLink to={"/instruments/"+instrument.name.toLowerCase().replace(" ","")}>{instrument.name}</NavLink></li>
        ))

    },[navOpen, lessonsOpen, props.instrumentsData])




    return (
        <header>
            <div className="pre-nav">
                <NavLink to="/"><img alt="JMS Logo" src={Logo}/></NavLink>
                <span className="toggle" onClick={handleNavToggle}>	&#9776;</span>
            </div>
            <nav className="main-nav">
                <ul>
                    <li onFocus={handleLessonsToggle} onBlur={handleLessonsToggle}>
                        <button className="lessons-toggle" >Private Lessons&#9662;</button>
                        <ul className="lessons-dropdown">
                            {instrumentsDropdown}
                            {/*<li><NavLink to="/">Acoustic Guitar</NavLink></li>*/}
                            {/*<li><NavLink to="/">Electric Guitar</NavLink></li>*/}
                            {/*<li><NavLink to="/">Piano</NavLink></li>*/}
                            {/*<li><NavLink to="/">Drums</NavLink></li>*/}
                            {/*<li><NavLink to="/">Ukulele</NavLink></li>*/}
                            {/*<li><NavLink to="/">Bass</NavLink></li>*/}
                            {/*<li><NavLink to="/">Violin</NavLink></li>*/}
                        </ul>
                    </li>
                    <li onClick={handleNavToggle}><NavLink to="/faculty">Faculty</NavLink></li>
                    <li onClick={handleNavToggle}><NavLink to="/about">About</NavLink></li>
                    <li onClick={handleNavToggle}><NavLink to="/faq">FAQ</NavLink></li>
                    <li onClick={handleNavToggle}><NavLink to="/contact">Contact</NavLink></li>
                    <li id="link-to-portal" onClick={handleNavToggle}><a href="https://portal.johnsonmusic.com.au" target="_blank">Portal</a></li>
                </ul>

            </nav>
        </header>
    )
}
