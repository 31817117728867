import {useEffect, useState} from "react";
import Callout from "./callout";
import InstrumentCards from "./instrumentCards";

export default function Home(props) {

    const [Slider, setSlider] = useState();

    useEffect(()=>{
        const rand = Math.floor(Math.random() * 3) + 1;
        setSlider(require(`../img/slider-${rand}.jpg`))
        }, []
    )

    return (
        <div>
            <div className="feature-image">
                <img alt="Johnson Music School Banner" src={Slider}/>
            </div>
            <main>
                <div className="container-homepage">
                    <h2>What do you want to learn?</h2>
                    <InstrumentCards instrumentsData={props.instrumentsData}/>
                </div>
            </main>
            <Callout/>
        </div>
    )
}
