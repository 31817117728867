import {useEffect, useState} from "react";
import axios from "axios";
import Teacher from "./teacher";
import HeaderAbout from "../img/header-about.jpg";
import Callout from "./callout";


export default function Faculty() {

    const [teachers, setTeachers] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get("https://api.johnsonmusic.com.au/fe/teachers")
            .then(res => {
                console.log(res.data)
                setTeachers(res.data);
                setLoading(false);
            })

    }, [])

    return (
        <div>
            <div className="page-header" style={{backgroundImage: `url(${HeaderAbout}`}}>
                <h1>The Faculty</h1>
            </div>
            <main>

                <div className="container-about">
                    <div className="faculty">
                        {
                            !loading ? (
                                [...teachers].map((teacher) => {
                                    return (
                                        <Teacher key={teacher.id} teacher={teacher}/>
                                    )
                                })
                            ) : "Loading..."
                        }
                    </div>

                    <Callout/>

                </div>

            </main>
        </div>
    )
}
