import {BrowserRouter as Router, Routes, Route} from "react-router-dom";

import './App.css';
import Header from "./components/header";
import Footer from "./components/footer";
import Home from "./components/home";
import Faculty from "./components/faculty";
import About from "./components/about";
import Faq from "./components/faq";
import Contact from "./components/contact";
import Instrument from "./components/instrument";
import {useEffect, useState} from "react";
import axios from "axios";
import NotFound from "./components/notfound";
import Logo from "./img/jms-logo.png";

function App() {

    const [instrumentsData, setInstrumentsData] = useState();
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        axios.get("https://api.johnsonmusic.com.au/fe/instruments")
            .then(res => {
                setInstrumentsData(res.data)
                setIsLoading(false)
            })
    }, [])

    if (isLoading) {
        return (
            <div className="loading" style={{width:"400px", margin:"0 auto", textAlign:"center", alignSelf:"center"}}>
                <img src={Logo} alt="JMS Logo"/>
                <h2>Website is under maintenance until end of February 2023. We're making things look better!</h2><br/>
                <h3>For info and inquiries please contact Rachel at 0432 633 453 or students@johnsonmusic.com.au.</h3><br/>
                <h3>After piano lessons? We are unfortunately at capacity! Please contact Heva at 0425 292 386 for a great piano teacher at the same location.</h3>
            </div>
        )
    } else {

        return (
            <Router>
                <Header instrumentsData={instrumentsData}/>
                <Routes>
                    <Route
                        path="/"
                        element={<Home instrumentsData={instrumentsData}/>}
                    />
                    <Route
                        path="/faculty"
                        element={<Faculty/>}
                    />
                    <Route
                        path="/about"
                        element={<About/>}
                    />
                    <Route
                        path="/faq"
                        element={<Faq/>}
                    />
                    <Route
                        path="/contact"
                        element={<Contact/>}
                    />
                    <Route
                        path="instruments/:instrument"
                        element={<Instrument instrumentsData={instrumentsData}/>}
                    />
                    <Route
                        path="*"
                        element={<NotFound/>}
                        />
                </Routes>
                <Footer/>
            </Router>
        );
    }
}

export default App;
