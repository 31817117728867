export default function Teacher(props) {

    const teacher = props.teacher;
    const TeacherImage = require(`../img/about-${teacher.first_name.toLowerCase()}.jpg`)

    console.log(teacher.instruments)
    const sorted_instruments = teacher.instruments.sort((a, b) => a.instrument.id - b.instrument.id)
    let instruments = sorted_instruments.map(instrument => instrument.instrument.name)
        return (
            <div className="faculty-member">
                <div className={`faculty-member-image ${teacher.junior ? "junior" : ""}`} style={{backgroundImage:`url(${TeacherImage}`}}></div>
                <h3>{teacher.first_name} {teacher.last_name}</h3>
                <h4>{instruments.join(", ")}</h4>
                <span>{teacher.qualifications}</span>
                <p>{teacher.description}</p>
            </div>
        )
}
