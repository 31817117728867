import Callout from "./callout";
import HeaderFaq from "../img/header-faq.jpg";
import {Link} from "react-router-dom";

export default function Faq() {

    return (
        <div>
            <div className="page-header" style={{backgroundImage:`url(${HeaderFaq}`}}>
                <h1>FAQ</h1>
            </div>
            <main>

                <div className="container-faq">
                    <h2>General</h2>
                    <h4>What are your teaching dates?</h4>
                    <p>We follow the NSW School Term Dates.</p>
                    <h4>Where is the school?</h4>
                    <p>We are located in Suite 106B, 30 Campbell Street, Blacktown. There is ample parking nearby in Colo
                        Lane Car Park or the Workers Club Car Park.</p>
                    <h4> Do I have to own my own instrument?</h4>
                    <p>It is suggested that you own your own instrument, as home-practice is essential in musical
                        learning. Students must bring their guitar, ukulele, bass instrument to class, along with
                        amp/pedalboard if requested by the teacher. A piano is available for lessons at the school.</p>
                    <h4>Do you teach adults?</h4>
                    <p>Yes! We cater to musicians of all ages and levels.</p>
                    <h4>How much home-practice is required?</h4>
                    <p>We recommend 10-15 minutes a day. We understand that practice opportunities differ between
                        families. Understand that the more practice partaken, the more enjoyment and improvement will
                        result. Know that it takes parental involvement as a young student is unlikely to intimate their
                        own practice.</p>

                    <hr/>

                        <h2>Pricing &amp; Payments</h2>
                        <h4>What are your lesson fees?</h4>
                        <p>Our 30-minute lessons with experienced teachers are priced at $38, invoiced monthly or by term. For junior teachers, we offer adjusted rates that reflect their skill level.</p>
                        <h4>How will I pay?</h4>
                        <p>Invoices will be issued at the beginning of the month/term for that month/term.</p>
                        <h4>What forms of payment do you accept?</h4>
                        <p>We accept bank transfer or cash, and a receipt can be issued upon request.</p>
                        <h4>Can I have a trial lesson?</h4>
                        <p>Yes! We offer a FREE 30-minute trial lesson for all new students. Just contact us to find a
                            suitable time and book!</p>


                        <hr/>

                            <h2>Policies</h2>
                            <h4>What if I miss a lesson?</h4>
                            <p>Lessons missed with less than 24-hour notice will not be refunded or credited. If a
                                24-hour notice is given, a catch-up lesson will be offered.</p>
                            <h4>How much notice must I give if I wish to discontinue?</h4>
                            <p>We require 1 month notice for discontinuation. Your final invoice will be adjusted
                                accordingly according to your last week of tuition</p>
                            <h4>What is your policy on sickness?</h4>
                            <p>If a student is unable to attend school or work due to illness, it is not reasonable for
                                them to attend music lessons. Consider the health of your music teacher and other music
                                students when making this decision. We have the right to refuse lessons to a sick
                                student.</p>
                            <h4>Do you have a Student Agreement?</h4>
                            <p>Yes. This will be presented to you to read and sign upon enrolment.</p>

                            <hr/>
                                <h4 style={{textAlign: 'center'}}>Have more questions? Feel free to <Link to="/contact">contact us</Link>!</h4>

                            <Callout/>

                </div>

            </main>
        </div>
    )
}
