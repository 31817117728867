import HeaderContact from '../img/header-contact.jpg';

export default function Contact() {

    return (
        <div>
            <div className="page-header" style={{backgroundImage: `url(${HeaderContact})`}}>
                <h1>Contact</h1>
            </div>
            <main>

                <div className="container-contact">
                    <div>
                        <h2>Email Us</h2>
                        <p><a href="mailto:students@johnsonmusic.com.au">students@johnsonmusic.com.au</a></p>
                        <h2>Call Us</h2>
                        <p>0432 633 453</p>
                        <h2>Find Us</h2>
                        <p><a target="_blank" href="http://g.page/johnsonmusic/">Suite 106B, 30 Campbell Street,Blacktown</a></p>
                        <h2>Parking info</h2>
                        <p><a target="_blank" href="location.pdf">Click here</a></p>
                        <div id="callout" style={{width:'100%'}}>
                            <h3>Claim your FREE trial lesson today!</h3>
                        </div>
                    </div>
                    <div className="map">
                        <h2>Map</h2>
                        <iframe width="100%" height="300px" frameBorder="0" style={{border: '0'}}
                                src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ96XiUGSZEmsRxmiJB_mEBhg&key=AIzaSyCnAhZq3bKBWgw9qcRK6WvhD8deZwjJZvg"
                                allowFullScreen></iframe>

                    </div>
                </div>

            </main>
        </div>
    )
}
