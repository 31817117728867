import {Link} from "react-router-dom";

export default function Callout() {

    return (
        <div id="callout">
            <h3>Claim your FREE trial lesson today!</h3>
            <Link to="/contact">Contact Us</Link>
        </div>
    )
}
