import Callout from "./callout";
import {useParams, Navigate} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";

export default function Instrument(props) {
    const [instrument, setInstrument] = useState();
    const [loading, setLoading] = useState(true);
    const instrumentsData = props.instrumentsData;
    const params = useParams();
    const paramInstrument = params.instrument;
    const instrument_object = instrumentsData.filter((instrument) => {
        return instrument.name.toLowerCase().replace(" ", "") === paramInstrument
    })[0]

    useEffect(() => {
        axios.get(`https://api.johnsonmusic.com.au/fe/instruments/${instrument_object.id}`)
            .then((response) => {
                console.log(response.data);
                setInstrument(response.data);
                setLoading(false);
            })
    }, [instrument_object.id])

    if (!loading) {

        if (!instrument) {
            return (
                <Navigate to="/not-found" replace/>
            )
        } else {
            const HeaderInstrument = require(`../img/header-${instrument.name.toLowerCase().replace(" ", "")}.jpg`)
            const ImgInstrument = require(`../img/instrument-${instrument.name.toLowerCase().replace(" ", "")}.jpg`)

            const capitalize = (str) => {
                return str.charAt(0).toUpperCase() + str.slice(1);
            }
            return (
                <div>
                    <div className="page-header" style={{backgroundImage: `url(${HeaderInstrument}`}}>
                        <h1>{instrument.name} Lessons</h1>
                    </div>
                    <main>

                        <div className="container-lessons">
                            <div className="desktop-only lesson-image"
                                 style={{backgroundImage: `url(${ImgInstrument}`}}>
                            </div>
                            <div>
                                <h2>{instrument.name}</h2>
                                <h3>Why {instrument.name}?</h3>
                                <ul>
                                    {
                                        instrument.reasons.map(reason =>
                                            <li key={reason.name}>{reason.name}</li>
                                        )
                                    }
                                </ul>
                                <h3>Some genres you can play</h3>
                                <ul>
                                    {
                                        instrument.genres.map(genre =>
                                            <li key={genre.name}>{capitalize(genre.name)}</li>
                                        )
                                    }
                                </ul>
                                <h3>What you will learn</h3>
                                <ul>
                                    {
                                        instrument.skills.map(skills =>
                                            <li key={skills.name}>{capitalize(skills.name)}</li>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>

                        <Callout/>

                    </main>
                </div>
            )
        }
    }
}
