import Callout from "./callout";
import HeaderAbout from "../img/header-about.jpg";

export default function About() {

    return (
        <div>
            <div className="page-header" style={{backgroundImage:`url(${HeaderAbout}`}}>
                <h1>About</h1>
            </div>
            <main>

                <div className="container-about">

                    <div className="general">
                        <div className="who">
                            <h3>Who are we?</h3>
                            <p>
                                Johnson Music is a warm and welcoming music school where every students’ musical
                                potential is
                                nurtured and grown. We cater to students of every level, style and genre. Students of
                                Johnson Music
                                leave classes feeling proficient, pleased with themselves, and with a growing passion
                                for musical
                                arts. Mark is an enthusiastic teacher who encourages creativity and excitement in
                                musical learning
                                and performance. Johnson Music is a place where music is celebrated, and where students
                                can grow.
                            </p>
                        </div>
                        <div className="mission">
                            <h3>Our Mission Statement</h3>
                            <p>
                                Johnson Music strives to provide a quality music education that is fun, engaging,
                                rewarding and
                                remains with a student for life. To facilitate a dynamic music education for beginner to
                                accomplished musicians of all skill levels, bringing out the musician that lies within
                                every
                                student.
                            </p>
                        </div>
                    </div>
                    <div className="why">
                        <div>
                            <h3>Tailored learning</h3>
                            <p>
                                Every student has their own way of learning, and at Johnson Music we take that into
                                account. We
                                cater to each student's needs and wants with their lessons, whether is working on a
                                single bar of
                                music or covering an entire piece in one go. Students are encouraged to learn a variety
                                of pieces
                                they're interested in, as well as learning scales and arpeggios to AMEB exam pieces.
                                Gamified
                                learning is employed, with special consideration of the student’s hobbies.
                            </p>
                        </div>
                        <div>
                            <h3>Family-run school</h3>
                            <p>
                                Johnson Music is owned and run by Mark Johnson, and the faculty is seen and treated as
                                family. Our
                                students’ musical education is of great importance to us not only as musical
                                professionals, but also
                                as family members wanting the best. Your experience with us is made sure to be easy and
                                pleasant,
                                enjoying clear communication and excellent management and support.
                            </p>
                        </div>
                        <div>
                            <h3>Love for music and teaching</h3>
                            <p>
                                Our faculty is made of musicians who are also educators. We understand what goes into a
                                successful
                                musical education, and we feed this knowledge into music lessons at Johnson Music. Young
                                students
                                who love learning music are often seen to thrive in other parts of life. Lessons are
                                rewarding and
                                utilizes gamification to engage students, with our best achievement being a students’
                                happiness.
                            </p>
                        </div>
                    </div>

                    <Callout/>

                </div>

            </main>
        </div>
    )
}
